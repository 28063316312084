/*
#Banner

Markup:

<div>
	<div class="banner">
		<div class="banner-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>

		<div class="wrapper wrapper--banner">
			<h1 class="banner-title">Make a<br />difference</h1>
		</div>
		<div class="skewedwrapper">
			<div class="wrapper">
				<div class="skewed skewed--left skewed--orange typography">
					<div class="skewedinner">
						<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

Styleguide 6.6

*/

.banner {
	overflow: hidden;
	position: relative;

	.banner-wrapper {
		position: absolute;
		bottom: 0;
	}
}

.banner-title {
	font-family: $font-heading;
	text-transform: uppercase;
	font-size: 42px;
	line-height: 40px;
	padding: 120px 0 34px;
	margin: 0;
	position: relative;
	z-index: 2;
	@include transition(0.2s);
	color: rgba(#fff, 0.95);

	@include respond(sm) {
		padding: 120px 0 34px;
	}

	@include respond(md) {
		font-size: 52px;
		line-height: 48px;
		padding: 140px 0 38px;
		max-width: 80%;
	}

	@include respond(lg) {
		padding: 160px 0 38px;
		max-width: 66%;
	}
}

.banner--image .banner-title {
	color: rgba(#fff, 1);
	text-shadow: 2px 6px 7px rgba(0,0,0,.2), 0 -5px 25px rgba(255,255,255,.2);

	@include respond(sm) {
		padding-top: 120px;
	}

	@include respond(md) {
		padding-top: 160px;
	}

	@include respond(lg) {
		padding-top: 180px;
	}
}

.banner-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-size: cover;
	background-position: right top;
	background-repeat: no-repeat;
}



