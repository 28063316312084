/**
# Slick

Template: Slick

Styleguide 6.3

*/
.slick {

	&-wrapper {
		clear: fix;
		margin: 0 25px 35px;

		@include respond(md) {
			margin: 10px auto 35px;
			max-width: 711px;
		}
		@include respond(lg) {
			max-width: 903px;
			margin: 10px auto 35px;
		}
		@include respond(xl) {
			max-width: none;
			margin: 10px -2px 35px;
		}
	}

	&-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	&-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	&-slider .slick-track,
	&-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	&-track {
		position: relative;
		left: 0;
		top: 0;
		//display: block;
		display: flex;


		&:before,
		&:after {
			content: "";
			display: table;
		}

		&:after {
			clear: both;
		}

		.slick-loading & {
			visibility: hidden;
		}
	}

	&-slide {
		float: left;
		height: 100%;
		display: none;

		display: flex;
    height: auto;
    align-items: center;

		[dir="rtl"] & {
			float: right;
		}
		img {
			display: block;
		}
		&.slick-loading img {
			display: none;
		}

		&.dragging img {
			pointer-events: none;
		}

		.slick-initialized & {
			display: block;
		}

		.slick-loading & {
			visibility: hidden;
		}

		.slick-vertical & {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}

	&-arrow.slick-hidden {
		display: none;
	}

	.slick-prev, .slick-next {

	}

	&-next,
	&-prev {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		width: 50px;
		height: 77px;
		margin-top: -15px;
		cursor: pointer;
		z-index: 10;
		border-radius: 0;
		@include transition(0.2s);
		background: none;

		&:after {
			opacity: 0.7;
			content: '';
			display: block;
			width: 50px;
			height: 77px;
			background: url("#{$svg-path}chevron.svg") no-repeat;
			@include transition(0.2);
		}

		.no-touchevents & {
			&:hover,
			&:focus {
				opacity: 0.9;
			}
		}

		&.slick-disabled {
			opacity: 0;
			cursor: default;

			&:hover,
			&:focus {
				opacity: 0;
			}
		}
	}

	&-prev {
		left: 20px;

		&:after {
			transform: rotate(180deg);
		}
	}
	&-next {
		right: 20px;
	}


	@include respond(lg) {
		&-prev {
			left: 40px;
		}
		&-next {
			right: 40px;
		}

	}

	&-dots {
		margin: 0;
		padding: 0;
		text-align: center;
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;

		li {
			list-style: none;
			margin: 0 6px;
			padding: 0;
			display: inline-block;
			font-size: 0;

			&.slick-active button:after {
				background: $brand-orange;
			}
		}

		button {
			border: 0;
			width: 20px;
			height: 0;
			padding-top: 20px;
			overflow: hidden;
			position: relative;
			background: none;

			&:after {
				content: ' ';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin: -5px 0 0 -5px;
				background: #fff;
			}

			&:focus,
			&:active {
				outline: 0;
			}

		}
	}
}
