/*
# Hamburger

The animated mobile menu icon.

Template: Hamburger

SectionTemplate: SGSectionDark

Styleguide 4.2
*/

.hamburger {
	background: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	font: inherit;
	margin: 11px 0 0 20px;
	padding: 7px 10px;
	overflow: visible;
	text-transform: none;
	transition-timing-function: linear;
	transition-duration: .15s;
	transition-property: opacity,-webkit-filter;
	transition-property: opacity,filter;
	transition-property: opacity,filter,-webkit-filter;
	border-radius: 0;

	&:hover,
	&:focus,
	&:active {
		border: 0;
		box-shadow: none;
		background: transparent;
		filter: none;
	}

	@include respond(md) {
		// Hide from the main menu on desktop
		// .menu & {
		// 	display: none;
		// }
	}

	.menu-active & {

		.hamburger-inner {
			transition-delay: .14s;
			transition-timing-function: cubic-bezier(.215,.61,.355,1);
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);

			&:before {
				top: 0;
				transition: top .1s ease,opacity .1s .14s ease;
				opacity: 0;
			}

			&:after {
				bottom: 0;
				transition: bottom .1s ease,-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
				transition: bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1);
				transition: bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1),-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
				-webkit-transform: rotate(-90deg);
				transform: rotate(-90deg);
			}
		}
	}

	&-text {
		color: #fff;
		margin-left: 5px;
		display: inline-block;
	}

	&-box {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 15px;
	}

	&-inner {
		background-color: #fff;
		display: block;
		height: 2px;
		margin-top: -2px;
		position: absolute;
		top: 50%;
		transition-duration: .1s;
		transition-property: transform,-webkit-transform;
		transition-timing-function: cubic-bezier(.55,.055,.675,.19);
		width: 20px;

		&:before,
		&:after {
			content: '';
			display: block;
			height: 2px;
			position: absolute;
			transition-duration: .15s;
			transition-property: -webkit-transform;
			transition-property: transform,-webkit-transform;
			transition-property: transform;
			transition-timing-function: ease;
			width: 20px;
			background-color: #fff;
		}

		&:before {
			top: -6px;
			transition: top .1s .14s ease,opacity .1s ease;
		}

		&:after {
			bottom: -6px;
			transition: bottom .1s .14s ease,-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
			transition: bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19);
			transition: bottom .1s .14s ease,transform .1s cubic-bezier(.55,.055,.675,.19), -webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
		}
	}
}
