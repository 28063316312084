/*
#Hero

Markup:

<div>
	<div class="herobanner">
		<div class="herobanner-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>
		<div class="header">
			<div class="wrapper">
				<a class="header-brand">
					<img src="themes/default/images/svg/logo-white.svg" alt="Ignite Sport" />
				</a>
				<button class="hamburger pure-button" data-toggle-self data-toggle="#main-menu" data-switchsearch data-switch-off="[data-switchmenu]">
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
					<span class="hamburger-text">Menu</span>
				</button>
			</div>
		</div>

		<div class="wrapper">
			<h1 class="herobanner-title">Impacting young<br />people & communities<br /><span>through sport</span></h1>
		</div>
		<div class="skewedwrapper">
			<div class="wrapper">
				<div class="skewed skewed--left skewed--orange typography">
					<div class="skewedinner">
						<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

Styleguide 6.5

*/

.herobanner {
	overflow: hidden;
	// min-height: 400px;
	position: relative;
	height: 100vh;
	max-height: 600px;

	@include respond(lg) {
		height: auto;
		max-height: none;
	}

	.skewedwrapper {
		position: absolute;
		bottom: 0;

		@include respond(lg) {
			position: relative;
		}
	}

	.wrapper--herobanner {
	}
}

.typpgraphy h1.herobanner-title,
h1.herobanner-title {
	font-family: $font-heading;
	text-transform: uppercase;
	color: $brand-orange;
	font-size: 42px;
	line-height: 38px;
	margin: 0;
	margin: 130px 0 0;
	padding: 0;
	position: relative;
	z-index: 2;
	@include transition(0.2s);

	span {
		color: rgba(#fff, 0.92);
	}

	@include respond(sm) {
		font-size: 52px;
		line-height: 44px;
		margin: 170px 0 0;
	}

	@include respond(md) {
		font-size: 62px;
		line-height: 54px;
		margin: 170px 0 0px;
		max-width: 80%;
		color: rgba($brand-orange, 0.92);

		span {
			color: rgba(#fff, 0.70);
		}
	}

	@include respond(lg) {
		font-size: 72px;
		line-height: 64px;
		margin: 200px 0 100px;
		max-width: 66%;
	}
}

.herobanner-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-size: cover;
	background-position: right top;
	background-repeat: no-repeat;
}