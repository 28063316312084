
/*
#Tables

Template: SGCMSTables

Styleguide 3.4
*/

.typography {

	table {
		@extend .pure-table !optional;
		background: $table-bgcolor;
		border: 1px solid $table-border-color;
		border-top: 5px solid $primary-color;
		border-bottom: 5px solid $primary-color;
		width: 100%;
		margin-bottom: 40px;
		table-layout: fixed;

		&.table--data {
			table-layout: auto;
		}

		@include respond(lg__max) {
			display: block;
			thead, tbody, th, td, tr {
				display: block;
			}
		}

		@include respond(print) {
			display: table;
			margin-top: 30px;

			thead {
				display: table-header-group;
			}
			tbody {
				display: table-row-group;
			}
			td, th {
				display: table-cell;
			}
			tr {
				display: table-row;
			}
		}

		thead {
			color: $table-thead-color;
			background-color: #fff;

			th {
				padding: 14px 14px;
				font-family: $font-bold;
				font-size: 16px;
				line-height: 24px;
				border-bottom: 1px solid $table-cell-border-color;
				border-right: 1px solid $table-cell-border-color;
			}

			tr {
				@include respond(lg__max) {
					position: absolute;
					top: -9999px;
					left: -9999px;
				}
			}
		}

		td, th {
			vertical-align: top;
			border-left: 0;
			border-right: 1px solid $table-cell-border-color;
			border-bottom: 1px solid $table-cell-border-color;

			&:last-child {
				border-right: 0;
			}
		}

		th {
			font-family: $table-header-font-family;
			font-weight: normal;
			text-align: left;
			border-right: 0;
		}

		td {
			@include respond(lg) {
				width: 40%;
			}
		}

		tbody {
			th {
				margin-bottom: 23px;
				font-size: 18px;
				font-family: $font-bold;
				line-height: 20px;

				a,
				a:visited {
					color: $primary-color;

					@include respond(print) {

						display: block;
						min-width: 100px;
						&:after {
							display: none;
						}
					}
				}

				@include respond(lg) {
					width: 20%;
					max-width: 150px;
					font-size: 20px;
					line-height: 25px;
				}
			}

			th,
			td {
				@include respond(lg__max) {
					position: relative;
				}

				@include respond(lg) {
					padding: 14px 14px;
				}
			}

			tr {

				th {
					border-top: $table-cell-border-size $table-cell-border-style $table-cell-border-color;
				}

				td {
					@include respond(lg) {
						border-top: $table-cell-border-size $table-cell-border-style $table-cell-border-color;
					}
				}

				&:first-child {
					th,
					td {
						border-top: 0;
					}
				}
			}
		}
	}
}

.typography .event-table,
.event-table {
	border: none;
	width: 100%;
	margin: 40px 0 20px;
	table-layout: fixed;
	border-bottom: 1px solid $table-border-color;

	&:last-child {
		margin-bottom: 0;
	}

	thead,
	tfoot {
		display: none;
	}

	tbody tr:first-child td {

		@include respond(md) {
			border-top: 1px solid $table-border-color;
		}
	}

	tbody tr td {
		border: none;
		padding: 0;
		width: 100%;
		display: block;
		// border-top: 1px solid $table-border-color;

		&:first-child {
			border-top: 1px solid $table-border-color;
			font-family: $font-bold;
			padding: 10px 0 10px;
		}

		&:last-child {
			padding: 0 0 14px;
		}

		*:last-child {
			margin-bottom: 0;
		}

		@include respond(md) {
			padding: 14px;
			display: table-cell;
			border-top: 1px solid $table-border-color;

			&:first-child {
				padding: 14px 14px 14px 0;
				width: 33.333%;
				max-width: 150px;
			}

			&:last-child {
				padding: 14px 0 14px 14px;
			}
		}
	}
}

.mceContentBody.typography .event-table {
	border: 1px solid $table-border-color;

	tbody tr td {
		border: 1px solid $table-border-color;

		&:last-child {
			border-right: 0;
		}
	}

}
