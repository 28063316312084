

.typography .contact-address,
.contact-address {
	margin: 0;
	padding: 0 20px 0 0;
	list-style: none;

	li {
		margin: 10px 0;

		.footer & {
			margin: 5px 0;
			font-size: 13px;
			line-height: 20px;
		}
	}
}

.typography p + .contact-address,
p + .contact-address {
	margin-top: 40px;
}

.contact-content {
	@include respond(sm) {
		padding-right: 30px;
	}

	@include respond(md) {
		padding-right: 50px;
	}
}

.contact-map {
	margin-top: 40px;
	@include respond(sm) {
		margin-top: 0;
	}

	iframe {
		width: 100%;
		max-width: 500px;
	}
}