
.element {
}

.element-black {
	background: $brand-black;
}

.element-blue {
	background: $brand-blue;
}

.element-orange {
	background: $brand-orange;
}

.element-red {
	background: $brand-red;
}

.element-black,
.element-blue,
.element-red,
.element-orange {
	h1, h2, h3, h4, h5, h6, p, li {
		color: #fff;
	}

	a {
		color: #fff;

		&:visited,
		&:hover {
			color: #fff;
		}
	}
}

.element--white:not(.element--image) + .element--white:not(.element--image) > div {
	padding-top: 0;
	// border-top: 1px solid rgba($brand-midgrey, 0.35);
}
.element--blue:not(.element--image) + .element--blue:not(.element--image) > div  {
	padding-top: 0;
	// border-top: 1px solid rgba(#fff, 0.5);
}
.element--black:not(.element--image) + .element--black:not(.element--image) > div  {
	padding-top: 0;
	// border-top: 1px solid rgba(#fff, 0.5);
}
.element--orange:not(.element--image) + .element--orange:not(.element--image) > div  {
	padding-top: 0;
	// border-top: 1px solid rgba(#fff, 0.5);
}
.element--red:not(.element--image) + .element--red:not(.element--image) > div  {
	padding-top: 0;
	// border-top: 1px solid rgba(#fff, 0.5);
}

.content .element > div.element-white {
	padding-top: 0;
}

.content .element:last-child > div {
	padding-bottom: 0;
}

.content + .element > div.element-white {
	padding-top: 0;
}

.content + .LandingElement {
	margin-top: -60px;
}

.ElementContent + .LandingElement {
	margin-top: -80px;
}









