/* Fonts */

// Note: rucksake font helper process doesn't yet work with sass variables
// the font-url: '../../../themes/default/fonts/fontname/';

// Uses the rucksake font helper
@font-face {
	font-family: 'OpenSans';
	font-path: '../../../themes/default/fonts/Open-Sans/OpenSans-Regular';
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSansBold';
	font-path: '../../../themes/default/fonts/Open-Sans/OpenSans-Bold';
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSansExtraBold';
	font-path: '../../../themes/default/fonts/Open-Sans/OpenSans-ExtraBold';
	font-weight: normal;
	font-style: normal;
}
