.content {
  padding: $component-padding 0;

  .typography > :last-child {
    margin-bottom: 0;
  }
}

.content1col {
	padding: $component-padding/2 0;
}

.content2col {
  padding: $component-padding 0 $component-padding - 40 0;
}

.content1col-content {
	max-width: 800px;

  > :first-child {
  	margin-top: 0;
  }

  > :last-child {
  	margin-bottom: 0;
  }
}

.content2col-cols {
	margin: 0;

	@include respond(md) {
		margin: 0 -30px;
	}

	@include respond(lg) {
	}
}

.content2col-item {
  padding: 0 0 20px 0;
  width: 100%;

  @include respond(md) {
		width: 50%;
		padding: 0 30px 40px 30px;
	}

  h3.content2col-item-title {
    font-size: 36px;
    line-height: 34px;
    letter-spacing: 0.75px;
    margin: 0 0 16px;
    text-transform: uppercase;
    font-family: $font-heading;
    color: $font-heading-color;
  }

  > :last-child {
  	margin-bottom: 0;
  }
}

.content2col-image {
  margin-bottom: 14px;

  img {
    width: 100%;
    max-width: 300px;
  }
}

.content2col-image + * {
  margin-top: 14px;
}
