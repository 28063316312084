/*
#Support

Markup:

<div>
	<div class="support">
		<div class="support-inner">
			<div class="support-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>
			<div class="support-upper">
				<div class="wrapper">
					<h3 class="support-title">Make a difference</h3>
					<p class="support-text">Vivamus suscipit tortor eget felis porttitor volutpat.
					Donec rutrum congue leo eget malesuada. Donec sollicitudin
					molestie malesuada. Vestibulum ac diam.</p>
				</div>
			</div>
			<div class="skewedwrapper">
				<div class="wrapper">
					<div class="skeweddouble">
						<div class="skewed skewed--left skewed--red typography">
							<div class="skewedinner">
								<h2>Volunteer</h2>
								<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
							</div>
						</div>
						<div class="skewed skewed--right skewed--red typography">
							<div class="skewedinner">
								<h2>Donate</h2>
								<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

Styleguide 6.8

*/

.support {
	position: relative;

	.skeweddouble {
		margin: 0;
	}
}

.support-upper {
	position: relative;
	z-index: 4;
	width: 100%;
	padding: $component-padding 0;
}

.support-title {
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 16px;
	border-bottom: $font-heading-border;

	@include respond(sm) {
		width: 66%;
		font-size: 36px;
		line-height: 34px;
		letter-spacing: 0.75px;
	}

	@include respond(md) {
		width: 50%;
	}
}

.support-text {
	width: 100%;

	@include respond(sm) {
		width: 66%;
	}

	@include respond(md) {
		width: 40%;
	}

	p {
		color: #fff;
		margin: 0 0 10px 0;
	}

	a,
	a:visited,
	a:focus {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}

.support-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-size: cover;
	background-position: right top;
	background-repeat: no-repeat;
}
