body.has-banner {
}

.wrapper {
	margin: 0 5%;

	@include respond(sm) {
		// margin: 0 5%;
	}

	@include respond(md) {
		// margin: 0 30px;
	}

	@include respond(xl) {
		margin: 0 auto;
		max-width: $container-width;
	}
	@include respond(print) {
		margin: 0;
		max-width: none;
	}
}
