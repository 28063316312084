

.sidenav {
	padding: 0 30px 30px 0;
	//display: none;

	//@include respond(md) {
	//	display: block;
	//}
}

h4.sidenav-title {
	text-transform: uppercase;
	padding: 5px 20px 7px 0;
	margin: 0 30px 15px 0;
	border-bottom: 1px solid rgba($brand-midgrey, 0.3);
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	font-family: $font-heading;
	color: $font-heading-color;

	a, a:hover, a:visited {
		color: $font-heading-color;
		text-decoration: none;
	}
}

.sidenav-menu {
	margin: 0;
	padding: 0 20px 0 0;

	li {
		margin: 4px 0;
		padding: 0;
		list-style: none;
		font-size: 14px;
		line-height: 22px;
	}

	a,
	a:visited,
	a:hover {
		color: $brand-midgrey;
		text-decoration: none;
		border: none;

		&.current {
			color: $font-color;
		}
	}
}