html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
	font-family: $font;
	-moz-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
}

/*
#Text elements

Template: SGCMSText

Generic text elements such as paragraphs, strong, em, sup, sub, hr and links

Styleguide 3.2
*/

%type--defaults {
	color: $font-color;
	font-family: $font;
	font-size: 14px;
	line-height: 22px;
	font-weight: normal;

	@include respond(print) {
		color: #000;
		font-size: 12pt;
		line-height: 1.5em;
	}
}

p {
	@extend %type--defaults;
	margin: 0;
	margin-bottom: 12px;

	@include respond(lg) {
		margin-bottom: 14px;
	}

	&.small {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 10px;
	}

	&.caps {
		text-transform: uppercase;
	}
}

.typography {
	@extend %type--defaults;

	p {
		@extend %type--defaults;
		margin: 0;
		margin-bottom: 12px;

		@include respond(lg) {
			margin-bottom: 14px;
		}

		@include respond(print) {
			orphans: 3;
			widows: 3;
			page-break-after: auto;
			page-break-inside: avoid;
		}

		&.small {
			font-size: 12px;
			line-height: 16px;
			margin: 0 0 7px;
		}

	}

	b, strong {
		font-family: $font-bold;
		font-weight: normal;
	}

	i, em {
		font-family: $font-italic;
		font-style: italic;
	}

	pre,
	blockquote {
		padding: 0;
		margin: 30px 0;
		position: relative;

		+ blockquote {
			margin-top: 0px;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: ' ';
			width: 34px;
			height: 20px;
			display: block;
			background-image: url(/themes/default/images/svg/quote-open.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 80%;
			float: left;
			margin-right: 2px;
		}

		@include respond(md) {

			&:before {
				float: none;
				position: absolute;
				top: 5px;
				left: -40px;
			}
		}

		@include respond(print) {
			page-break-inside: avoid;
		}
	}

	hr {
		border: 0;
		border-bottom: 5px solid $primary-color;
		margin: 20px 0;

		@include respond(print) {
			border-top: 5px solid #000;
		}
	}

	&.element--black {
		p {
			color: #fff;
		}
	}
}
