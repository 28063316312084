/*
#Sponsors

Markup:

<div>
</div>

Styleguide 6.9

*/

.sponsors {
	position: relative;
	padding: $component-padding 0 $component-padding - 10;
}

.sponsors-intro {
	margin-bottom: 30px;
	max-width: 800px;

	p {

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

}

.sponsors-group {
	margin: 0 0 30px 0;

	&:last-child {
		margin-bottom: 0;
	}
}

.sponsors-group-title {
	color: $font-heading-color;
	text-transform: uppercase;
	font-family: $font-heading;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 8px;
	border-bottom: $font-heading-border-dark;

	@include respond(sm) {
		width: 66%;
	}

	@include respond(md) {
		width: 50%;
	}
}

.sponsors-items {
	max-width: 960px;
	margin: 0 -15px 0 -10px;
	padding: 0 0 0;

	@include respond(sm) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	@include respond(md) {
		margin: 0 -15px 0 -15px;
	}
}

.sponsors-item {
	margin: 0;
	padding: 10px 10px;
	display: block;
	line-height: 0;

	a, img, span {
		display: inline-block;
	}

	img {
		max-width: 120px;
    	max-height: 120px;
	}

	a {
		opacity: 0.6;
		transition: 300ms;
		text-decoration: none;
		color: #585858;

		&:hover,
		&:visited {
			color: #585858;
			opacity: 1;
		}
	}

	@include respond(md) {
		padding: 15px 15px;

		a, img {
			display: block;
		}

		img {
			max-width: 150px;
	    	max-height: 150px;
		}
	}

	span.sponsors-named {
		font-size: 16px;
		line-height: 22px;
		max-width: 180px;
    	display: inline-block;
    	vertical-align: middle;
    	font-family: $font-extrabold;
    	letter-spacing: -1px;
    	border: 1px solid rgba(134,134,134,.35);
    	padding: 10px;
	}

}

