/*
 * A file for Custom mixins and placeholders
 */

// Responsive Mixins

// NOTE: the first two media queries are to limit styles to mobile sizes
// xs doesn't need a media query to apply styles - they will usually
// be the default
@mixin respond($media) {

	@if $media == xs__max {
		@media screen and (max-width: $respond-xs - 1) {
			@content;
		}
	}

	// everything up to regular tablet size
	@if $media == md__max {
		@media screen and (max-width: $respond-md - 1) {
			@content;
		}
	}

	// at least small tablet size
	@else if $media == sm {
		@media screen and (min-width: $respond-sm) {
			@content;
		}
	}
	// at least regular tablet size
	@else if $media == md {
		@media screen and (min-width: $respond-md) {
			@content;
		}
	}
	// at least desktop
	@else if $media == lg {
		@media screen and (min-width: $respond-lg) {
			@content;
		}
	}
	@else if $media == xl {
		@media screen and (min-width: $respond-xl) {
			@content;
		}
	}

	@else if $media == print {
		@media print { @content; }
	}
	@else {
		@media only screen and ('#{$media}') { @content; }
	}
}



@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
