/*
#Images

Template: SGCMSImages

Styling for CMS images inserted via tinymce. Options are: <br /><br />
&bull; Left, text wrapping<br />
&bull; Right, text wrapping<br />
&bull; Left alone (no text wrapping)<br />
&bull; Centered alone (no text wrapping)<br /><br />
... with optional captions


Styleguide 3.3.1
*/


svg {
	max-width: 100%;
}

iframe {
	border: 0;
	max-width: 100%;
	max-height: 230px;
	@include respond(sm) {
		max-height: 300px;
	}
	@include respond(md) {
		max-height: 350px;
	}
	@include respond(lg) {
		max-height: 415px;
	}
}

img {
	max-width: 100%;
	height: auto;
	display: block;

	&.left {
		float:left;
		clear:left;
		margin: 20px 30px 20px 0px;

		&:first-child {
			margin-top: 7px !important;
		}

		@include respond(md) {
			max-width: 50%;

			.content__page & {
				margin-left: -25px;
			}
		}
	}

	&.right {
		float:right;
		clear:right;
		margin: 10px 0 20px 30px;

		&:first-child {
			margin-top: 7px !important;
		}

		@include respond(md) {
			max-width: 50%;
		}
	}

	// full width left
	&.leftAlone {
		margin: 10px 30px 20px 0;
		float: none;
		display: block;
		clear: both;

		.content__page & {
			margin-left: -25px;
		}

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	&.center {
		display:block;
		margin: 30px auto 30px auto;
		clear: both;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	&.full {
		display:block;
		margin: 30px auto 30px auto;
		clear: both;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}

/*
#Media (youtube, etc)

CMS Styling for youtube, vimeo etc media included via oembed. These work much the same as images. Options are: <br /><br />
&bull; Left, text wrapping<br />
&bull; Right, text wrapping<br />
&bull; Left alone (no text wrapping)<br />
&bull; Centered alone (no text wrapping)

Template: SGCMSMedia

Styleguide 3.3.2
*/


/* Media insert formatting  (Youtube embed) */
.media {
	&.center {
		margin:0 auto;
		text-align:center;
	}
	&.left {
		float:left;
		clear:left;
		margin: 0 30px 30px 0px;

		.content__page & {
			margin-left: -25px;
		}
	}

	&.leftAlone {
		margin: 0 30px 30px 0;
		float: none;
		display: block;
		clear: both;

		.content__page & {
			margin-left: -25px;
		}
	}

	&.right {
		float:right;
		clear:right;
		margin: 0 0 30px 30px;
	}
}

figure.featured-image {

	img {
		margin-bottom:0;
	}
}

.captionImage, figure.featured-image {
	max-width: 100%;

	&.left {
		float: left;
		margin: 5px 30px 30px 0px;

		@include respond(md) {
			max-width: 50%;
		}

		.content__page & {
			margin-left: -25px;

			img{
				margin-left: 0px;
			}
		}
	}

	&.right {
		float:right;
		margin: 5px 0 30px 30px;

		@include respond(md) {
			max-width: 50%;
		}
	}
	&.leftAlone {
		float:none;
		margin: 5px 30px 30px 0px;
	}

	&.center {
		margin: 5px auto 30px;
	}

	&.left,
	&.right,
	&.leftAlone,
	&.center {
		@include respond(sm) {
			margin-bottom:30px;
			margin-top:10px;
			p {
				text-align: left;
				margin: 0px 0 0 0;
			}
		}
	}

	p {
		clear: both;
		margin: 0px;
		text-align: left;
		padding-top: 1px;
		font-weight: normal;
		font-family: $font;
		font-style: italic;
		padding-bottom: 10px;
		font-size: 12px;
		line-height: 18px;
		position: relative;

		.typography & {
			font-size: 12px;
			line-height: 18px;
			margin: 0px;
		}
	}

	p:after {
		content: ' ';
		border-bottom: 1px solid rgba($brand-midgrey, 0.3);
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;

		@include respond(md) {
			width: 80%;
			max-width: 400px;
		}
	}

	img {
		margin: 0 0 5px 0;
		border: 0;

		@include respond(md) {
			max-width: 100%;
		}
	}
}
