/*
#Landing

Styleguide 6.2

*/


.landing {
	position: relative;
	padding: $component-padding 0;
}

.landing-title {
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 24px;
	border-bottom: $font-heading-border-dark;
	color: $font-heading-color;

	@include respond(sm) {
		width: 66%;
		font-size: 32px;
		line-height: 28px;
	}

	@include respond(md) {
		width: 50%;
	}

	.element-black &,
	.element-blue &,
	.element-red &,
	.element-orange & {
		color: #fff;
		border-bottom: $font-heading-border;
	}
}

.landing-cols {
	max-width: 960px;
	margin: -15px -15px;

	@include respond(md) {
	}

	@include respond(lg) {
	}

	&.landing-cols--flex {
		display: flex;
		flex-wrap: wrap;
	}
}

.landing-link {
	margin: 24px 0 -4px 0;
	text-transform: uppercase;

	a {
		color: $font-color;
		text-decoration: none;

		&:hover {
			color: $font-color;
		}

		.element-black &,
		.element-blue &,
		.element-red &,
		.element-orange & {
			color: #fff;

			&:hover {
				color: #fff;
			}
		}
	}
}

.landing-item {
	padding: 5px;
	width: 100%;

	.landing-cols--flex & {
		display: flex;
	}

	@include respond('min-width:440px') {
		width: 50%;
	}

	@include respond(sm) {
		width: 33.333%;
	}

	@include respond(md) {
		width: 25%;
	}
}

.landing-item-link {
	display: block;
	width: 100%;
	padding: 10px;
	transition: 0.3s;
	text-decoration: none;

	&:hover {
		background: lighten($brand-black, 80%);
	}

	.element-white & {
		&:hover {
			background: lighten($brand-black, 80%);
		}
	}

	.element-blue & {
		&:hover {
			background: darken($brand-blue, 5%);
		}
	}

	.element-black & {
		&:hover {
			background: lighten($brand-black, 3%);
		}
	}

	.element-red & {
		&:hover {
			background: darken($brand-red, 5%);
		}
	}

	.element-orange & {
		&:hover {
			background: darken($brand-orange, 5%);
		}
	}
}

.landing-item-title {
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0px;
	margin: 0 0 5px 0;
	color: $font-heading-color;

	.element-black &,
	.element-blue &,
	.element-red &,
	.element-orange & {
		color: #fff;
	}
}

.landing-item-small {
	margin: 0;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
}

.landing-item-summary {
	font-size: 13px;
	line-height: 19px;
	margin: 0;
}

.landing-item-small,
.landing-item-summary {
	color: $font-color;

	.element-black &,
	.element-blue &,
	.element-red &,
	.element-orange & {
		color: #fff;
	}
}

.landing-item-small + .landing-item-summary {
	margin: 8px 0 0;
}

.landing-item-image {
	margin-bottom: 10px;

	img {
		width: 100%;
		max-width: 300px;
	}
}


.landing--fullwidth {

	.landing-item {
		width: 100%;
		clear: both;
		overflow: hidden;
		padding: 40px 0;
		border-bottom: $font-heading-border-dark;

		&:first-child {
			padding-top: 30px;
		}
	}

	.landing-item-image {
		margin: 0 0 20px 0;

		img {
			display: block;
		}
	}

	.landing-item-content {
		padding-left: 0;
	}

	.landing-item-more {
		margin: 0;
	}

	@include respond(md) {

		.landing-item-image {
			float: left;
			margin: 0;
		}

		.landing-item-content {
			padding-left: 300px;
		}

	}

}

.landing--fullwidth.element-blue {

	.landing-item {
		border-bottom: 1px solid rgba(#fff, 0.5);
	}
}


