.teammember-header {
	margin-bottom: 24px;
	position: relative;

	img {
		max-width: 100%;
		width: 100%;
	}

	@include respond(md) {
		margin-bottom: 30px;
	}
}


.teammember-header-inner {

	.teammember-header--image & {
		padding: 14px 0 0;
	}

	h1 {
		margin: 0;
	}

	p {
		margin: 5px 0 0;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;
	}

	@include respond(sm) {

		.teammember-header--image & {
			padding-left: 15px;
			position: absolute;
			bottom: -3px;
		}
	}

	@include respond(md) {
		.teammember-header--image & {
			padding-left: 25px;
		}
	}
}