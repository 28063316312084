// Pure form overrides
form {

	fieldset {
		padding: 0;
		margin: 0;
		border: none;
	}

	label {
		color: $font-color;
		font-family: $font;
		font-size: 14px;
		line-height: 24px;
		max-width: 100%;
		display: block;
		margin: 0;
	}

	.element--black & {

		label {
			color: #fff;
		}
	}

}

div.field {
	margin-bottom: 10px;
}

input.text {
	background-color: #ffffff;
	color: $font-color;
	font-family: $font;
	font-size: 14px;
	line-height: 24px;
	width: 100%;

	@include respond(md) {
		font-family: $font;
		font-size: 14px;
	}

	.field & {
		border: 1px solid $brand-midgrey;
		border-radius: 0;
		box-shadow: none;
		box-sizing: border-box;
		height: 42px;
		padding: 10px 8px;
	}

	.element--black .field & {
		border: none;
		height: 44px;
	}
}

.field.checkbox,
.field.radio {

	input {
		float: left;
		margin: 5px 5px 0 0;
	}

}


.Actions .action {
	margin: 0;
	border: none;
	background: $brand-blue;
	color: #fff;
	text-transform: uppercase;
	font-family: $font-extrabold;
	font-size: 14px;
	line-height: 24px;
	padding: 10px 14px;
	transition: 200ms;

	&:hover {
		background: rgba($brand-blue, 0.8);
	}
}

.fieldset { //Silverstripe uses fieldsets wrongly, so don't style with default
	border: none;
}

#ForgotPassword { //ID only used because silverstripe does not provide a class :(
	font-size: 14px;
	line-height: 24px;
	margin-top: 30px;
}

.parsley-errors-list {
	margin: 0;
	padding: 0;

	li {
		font-size: 14px;
		line-height: 20px;
		list-style: none;
		margin: 0;
		padding: 4px 0 0 0;
		font-style: italic;
	}
}

