/*
#Stats

Markup:

<div>
	<div class="stats">
		<div class="wrapper">
			<h2 class="stats-title">2016 at a glance</h2>
			<div class="stats-outer">

					<div class="stats-item">
						<div class="stats-item-upper">
							<img class="stats-item-icon" src="/themes/default/images/stat-man-single.svg">
							<span class="stats-item-num">980</h4>
						</div>
						<p class="stats-item-text">Students took part in our programmes at 11 schools</p>
					</div>

					<div class="stats-item">
						<div class="stats-item-upper">
							<img class="stats-item-icon" src="/themes/default/images/stat-man-single.svg">
							<span class="stats-item-num">980</h4>
						</div>
						<p class="stats-item-text">Students took part in our programmes at 11 schools</p>
					</div>

					<div class="stats-item">
						<div class="stats-item-upper">
							<img class="stats-item-icon" src="/themes/default/images/stat-man-single.svg">
							<span class="stats-item-num">980</h4>
						</div>
						<p class="stats-item-text">Students took part in our programmes at 11 schools</p>
					</div>

					<div class="stats-item">
						<div class="stats-item-upper">
							<img class="stats-item-icon" src="/themes/default/images/stat-man-single.svg">
							<span class="stats-item-num">980</h4>
						</div>
						<p class="stats-item-text">Students took part in our programmes at 11 schools</p>
					</div>

			</div>
		</div>
	</div>
</div>

Styleguide 6.9

*/


.stats-outer {
	overflow: auto;
	width: 100%
}

.stats-title {
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 16px;
	border-bottom: $font-heading-border;

	@include respond(sm) {
		width: 66%;
		font-size: 36px;
		line-height: 34px;
		letter-spacing: 0.75px;
	}

	@include respond(md) {
		width: 50%;
	}
}

.stats-intro {
	max-width: 800px;
	margin-bottom: 32px;

	p {
		color: #fff;
		margin: 0 0 10px 0;
	}

	a,
	a:visited,
	a:focus {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}

.stats-item {
	display: block;
	margin: 0;
	padding: 10px 20px 20px 0;
	float: left;
	width: 50%;
	max-width: 200px;

	&:nth-child(2n+1) {
		clear: left;
	}

	@include respond(md) {
		width: 25%;
		padding: 10px 30px 0 0;

		&:nth-child(2n+1) {
			clear: none;
		}
	}
}

.stats-item-icon {
	display: inline-block;
	vertical-align: bottom;
	margin-right: 2px;
	margin-bottom: 4px;
	max-width: 105px;
	height: 50px;
}

.stats-item-num {
	display: inline-block;
	vertical-align: bottom;
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;

	@include respond(md) {
		font-size: 32px;
		line-height: 32px;
	}
}

.stats-item-text {
	display: block;
	color: #fff;
	margin: 4px 0 0 0;
	font-size: 14px;
	line-height: 20px;
}






