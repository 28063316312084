/*
#Skew

Markup:

<div>
	<div class="skewedwrapper">
		<div class="wrapper">
			<div class="skewed skewed--left skewed--orange typography">
				<div class="skewedinner">
					<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
				</div>
			</div>
		</div>
	</div>

	<div class="skewedwrapper">
		<div class="wrapper">
			<div class="skeweddouble">

					<div class="skewed skewed--left skewed--orange typography">
						<div class="skewedinner">
							<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
						</div>
					</div>
					<div class="skewed skewed--right skewed--orange typography">
						<div class="skewedinner">
							<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
						</div>
					</div>
			</div>
		</div>
	</div>

	<div class="skewedwrapper">
		<div class="wrapper">
			<div class="skeweddouble">
				<div class="skewed skewed--left skewed--red typography">
					<div class="skewedinner">
						<h2>Volunteer</h2>
						<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
					</div>
				</div>
				<div class="skewed skewed--right skewed--red typography">
					<div class="skewedinner">
						<h2>Donate</h2>
						<p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

Styleguide 6.4

*/

$gap: 6px;
$angle: -15deg;

.skewedwrapper {
	overflow: hidden;
}

.skeweddouble {
	margin-top: 50px;

	.skewed + .skewed {
		margin-top: 2px;
	}

	@include respond(md) {
		display: flex;
		flex-direction: row;

		.skewed + .skewed {
			margin-top: 0;
		}

		.skewed {
			display: flex;

			p {
			}
		}

		.skewed--left {
			width: 55%;
			float: left;
		}

		.skewed--left:before {
			right: $gap;
		}

		.skewed--right {
			width: 45%;
			float: left;
		}

		.skewed--right:before {
			left: $gap;
		}

		.skewed--right .skewedinner {
			padding-left: 30px + (4 * $gap);
			padding-right: 0;
		}
	}

	@include respond(lg) {
		.skewed--left {
			width: 60%;
		}

		.skewed--right {
			width: 40%;
		}
	}
}

.skewed {
	position: relative;
	z-index: 2;
	margin: 0;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: -5.5556%;
		left: -5.5556%;
		z-index: 1;
	}

	p {
		margin: 0 0 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a,
	a:visited,
	a:focus {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}

	@include respond(sm) {
		margin: 0;
	}

	@include respond(md) {
		margin: 0;
		width: 80%;

		&:before {
			left: -100%;
			transform: skew($angle);
		}
	}

	@include respond(lg) {
		width: 70%;
	}
}

.banner .skewed p {
	font-size: 14px;
	line-height: 22px;

	@include respond(md) {
		font-size: 16px;
		line-height: 24px;
	}
}

.skewed--left {
}

.skewed--right {

	@include respond(md) {
		margin: 0 -30px 0 0;
		width: 70%;

		&:before {
			right: -100%;
			left: 0;
		}
	}
}

.skewed--orange {
	&:before {
		background: rgba($brand-orange,.95);
	}
}

.skewed--red {
	&:before {
		background: rgba($brand-red,.95);
	}

	p, h2, h3, h4 {
		color: #fff;
	}
}

.skewed--blue {
	&:before {
		background: $brand-blue;
	}

	p, h2, h3, h4 {
		color: #fff;
	}
}

.skewedinner {
	padding: 25px 0px 25px 0;
  z-index: 3;
  position: relative;

  @include respond(sm) {
		padding: 25px 40px 25px 0;
	}

	@include respond(md) {
		padding: 25px 80px 25px 0;
	}
}

.skewed-title {
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	margin: 0 0 12px;
}

