.programmes {
	position: relative;
	padding: $component-padding 0 60px;
}

.programmes-intro {
	margin: 0 0 46px;
	max-width: 800px;

	*:last-child {
		margin-bottom: 0;
	}
}

.programmes-group {
	padding-bottom: $component-padding / 2;

	&:last-child {
		padding-bottom: 0;
	}
}

.programmes-group-title {
	text-transform: uppercase;
	font-family: $font-heading;
	color: $font-heading-color;
	font-size: 24px;
	line-height: 26px;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 24px;
	border-bottom: $font-heading-border-dark;

	@include respond(sm) {
		width: 66%;
		font-size: 36px;
		line-height: 34px;
		letter-spacing: 0.75px;
	}

	@include respond(md) {
		width: 50%;
	}
}

.programmes-group-intro {
	margin: 0 0 24px;
	max-width: 800px;

	*:last-child {
		margin-bottom: 0;
	}
}

.programmes-items {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -10px;
}

.programmes-item {
	width: 100%;
	padding: 0 10px 26px;
	display: flex;

	@include respond(sm) {
		width: 50%;
		padding: 0 10px 20px;
	}

	@include respond(md) {
		width: 33.333%;
	}

	@include respond(lg) {
		width: 25%;
	}
}

.programmes-item a {
	display: block;
	position: relative;
	padding: 10px;
	background: #5396E4;
	text-decoration: none;
	color: #fff;
	@include transition(0.2s);
	width: 100%;

	&:hover {
		background: rgba(#5396E4, 0.9);
	}
}

// .programmes-item--arrow {
// 	opacity: 0;
// 	-webkit-animation: fadein 1s;
//    -moz-animation: fadein 1s;
//     -ms-animation: fadein 1s;
//      -o-animation: fadein 1s;
//         animation: fadein 1s;
// }

.programmes-item--arrow a {

	&:after {
		position: absolute;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		pointer-events: none;
		border-width: 14px;
		border-color: rgba(255,255,255,0);
		border-top-color: #5396E4;
		bottom: -28px;
		right: 50%;
		margin-right: -14px;

		@include respond(sm) {
			bottom: auto;
			top: 14px;
			right: -28px;
			margin: 0;
			border-color: rgba(255,255,255,0);
			border-left-color: #5396E4;
		}
	}

	&:hover:after {
		border-color: rgba(255,255,255,0);
		border-top-color: rgba(#5396E4, 0.9);

		@include respond(sm) {
			border-color: rgba(255,255,255,0);
			border-left-color: rgba(#5396E4, 0.9);
		}
	}
}

.programmes-item--arrow:last-child a:after {
	display: none;
}

.programmes-item-image {
	margin-bottom: 10px;
}

.programmes-item-title {
	color: #fff;
	// font-family: $font-heading;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 20px;
	margin: 0;
}

.programmes-item-subtitle {
	color: #fff;
	font-family: $font;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
}

// @for $i from 1 through 20 {
// 	$delay: (1 + (.25 * $i)) * 1s;
// 	.programmes-item--arrow:nth-child(#{$i}) {
// 		-webkit-transition-delay: $delay;
//     -moz-transition-delay: $delay;
//     -o-transition-delay: $delay;
//     transition-delay: $delay;
// 		-webkit-animation-delay: $delay;
// 	}
// }




