/*
#Text elements

Template: SGCMSText

Generic text elements such as paragraphs, strong, em, sup, sub, hr and links

Styleguide 3.2
*/

%decorated-link {
	left: 0;
	margin: 0 5px 0 0px;
	@include transition(0.2s);

	&:before {
		color: $font-color;
		font-size: 1.4em;
		line-height: 1.2em;
		position: relative;
		top: 2px;
		text-decoration: none;
		zoom: 1;
		display: inline-block;
		width: 20px;
		height: 20px;
		text-align: center;
		margin-right:3px;
		@include transition(0.2s);
	}
}

a {
	color: $link-color;
	// text-decoration: underline;
	@include transition(0.2s);

	&:visited {
		color: $link-visited-color;
	}

	&:hover,
	&:focus {
		color: $link-hover-color;
	}
}

p a, li a {
	text-decoration: none !important;
	border-bottom-color: currentColor;
	border-bottom-style: dotted;
	border-bottom-width: 1px;
}

.typography {
	a {
		h3 & {
			color: $font-color;
			i {
				color: $link-color;
			}
		}

		&:visited {
			color:$link-visited-color;

			h3 & {
				color: $font-color;
			}
		}

		&:hover, &:focus {
			color:$link-hover-color;

			h3 & {
				color:$link-color;
			}
		}
	}

	&.element--black {
		a {
			color: #fff;
			// text-decoration: underline;

			&:visited, &:hover, &:focus {
				color: #fff;
			}
		}
	}
}

a[href$=".png"],
a[href$=".PNG"],
a[href$=".gif"],
a[href$=".GIF"],
a[href$=".jpg"],
a[href$=".JPG"],
a[href$=".jpeg"],
a[href$=".JPEG"] {
	@extend %decorated-link;
	&:before {
	}
}

a[href$=".doc"],
a[href$=".DOC"],
a[href$=".docx"],
a[href$=".DOCX"],
.doc,
a[href$=".xls"],
a[href$=".XLS"],
a[href$=".xlsx"],
a[href$=".XLSX"],
.xls,
a[href$=".pdf"],
a[href$=".PDF"],
.pdf {
	@extend %decorated-link;
	&:before {
	}
}

a .fileExt,
a .file-ext {
	display: inline-block;
	margin-left: 5px;
	color: #9B9B9B !important;
	text-decoration: none !important;
}

.external {
	@extend %decorated-link;
	&:before {
	}
}

.typography ul.downloads,
ul.downloads {
	margin: 0 0 0 0;
	padding: 0;
}

li.download,
p.download {
	margin: 0;
	padding: 5px 0;
	list-style: none;

	&:last-child {
		margin-bottom: 0;
	}
}

li.download a,
li.download a:visited,
p.download a,
p.download a:visited,
a.download,
a.download:visited {
	position: relative;
	display: inline-block;
	padding: 10px 0 10px 50px;
	line-height: 20px;
	text-decoration: none;
	color: $font-color;
	@include transition(0.2s);
	border: none;

	&:before {
		content: ' ';
		width: 40px;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-image: url("#{$svg-path}download.svg");
		background-position: center center;
		background-repeat: no-repeat;
		background-color: #5396E4;
		border-radius: 20px;
		@include transition(0.2s);
	}

	&:hover {
		color: $font-color;

		&:before {
			background-color: darken(#5396E4, 10%);
		}
	}
}

