
body.menu-active {
	position: fixed;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
}

.nav-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #000;
	background: rgba(#000, 0.85);
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: visibility 0s linear $nav-speed, opacity $nav-speed linear;

	.menu-active & {
		visibility: visible;
		opacity: 1;
		transition-delay: 0s;
	}
}

.nav {
	position: fixed;
	top: 0;
	left: 0;
	right: auto;
	bottom: 0;
	left: 0;
	width: 80%;
	max-width: 500px;
	background: #2961a9;
	background: -moz-linear-gradient(top, #2961a9 0%, #0990d1 100%);
	background: -webkit-linear-gradient(top, #2961a9 0%,#0990d1 100%);
	background: linear-gradient(to bottom, #2961a9 0%,#0990d1 100%);
	z-index: 11;
	transition: 0.3s;
	padding: 0 60px 50px 0;
	padding-left: 5vw;
	opacity: 0;
	visibility: hidden;
	transition: visibility 0s linear $nav-speed, opacity $nav-speed linear;
	overflow-y: scroll;
	overflow-x: hidden;
	transform: translateZ(0);

	@include respond(xl) {
		padding-right: 80px;
		padding-left: calc((100% - 1000px) / 2);
		max-width: 50%; //calc(((100% - 1000px) / 2) + 500px);
		//max-width: 600px;
	}

	.menu-active & {
		visibility: visible;
		opacity: 1;
		transition-delay: 0s;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 20px;
		line-height: 24px;
		font-family: $font-heading;
		text-transform: uppercase;
		margin: 0 0 24px 0;
	}

	li a {
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid rgba(#fff, 0.25);
		display: block;
		padding: 0 0;

		&:active,
		&:hover {
			color: #fff;
		}
	}

	li li {
		font-size: 14px;
		line-height: 20px;
		font-family: $font;
		text-transform: none;
		padding: 0;
		margin: 6px 0 6px 0;
	}

	li li a {
		display: inline-block;
		border: none;
		padding: 0;
	}

	li li ul {
		margin-left: 20px;
	}

	.nav-text p {
		font-size: 14px;
		line-height: 22px;
		font-family: $font;
		color: #fff;
		text-transform: none;
	}

	.nav-text a {
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid rgba(#fff, 0.25);
		padding: 0;

		&:active,
		&:hover {
			color: #fff;
		}
	}

}

.nav-header {
	padding: 15px 0 40px;

	@include respond(md) {
		padding: 20px 0 50px;
	}
}


