/*
#Headings

Template: SGCMSHeadings

Headings come in two fonts: serifed, and sans serif. The serifed font is our default. To use the sans-serif font, add
``class="sans"`` to your heading

Styleguide 3.1
*/

.typography {
	h1, h2, h3, h4, h5, h6 {
		font-family: $font-heading;
		color: $font-heading-color;
		line-height: 1.3em;

		&:first-child {
			margin-top: 0;
		}
	}

	h1 {
		font-size: 52px;
		line-height: 52px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0px;
	}

	h2 {
		font-size: 36px;
		line-height: 34px;
		letter-spacing: 0.75px;
		text-transform: uppercase;
		font-weight: 700;
		margin: 42px 0 16px 0;
	}

	h3 {
		font-size: 24px;
		line-height: 24px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0px;
		margin: 32px 0 16px 0;
	}

	h4 {
		font-size: 18px;
		line-height: 18px;
		font-weight: 700;
		letter-spacing: 0px;
		margin: 24px 0 14px 0;
	}

	h5 {
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		letter-spacing: 0px;
		margin: 0 0 12px 0;
	}

	h6 {
		font-family: $font;
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		letter-spacing: 0px;
		margin: 0 0 10px 0;
	}

	&.element--black {
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
	}

}


