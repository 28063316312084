/*
#Stats

Markup:

<div>

</div>

Styleguide 7.0

*/



.signup {
	position: relative;
	padding: $component-padding 0;
	background: $brand-black;
}

.signup-title {
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;
	width: 80%;
	padding: 0 0 8px 0;
	margin: 0 0 16px;
	border-bottom: $font-heading-border;

	@include respond(sm) {
		width: 66%;
		font-size: 36px;
		line-height: 34px;
		letter-spacing: 0.75px;
	}

	@include respond(md) {
		width: 50%;
	}
}

.signup-intro {
	width: 100%;

	@include respond(md) {
		width: 66%;
	}

	@include respond(lg) {
		width: 50%;
	}

	p {
		margin: 16px 0;
		color: #fff;
	}
}

.signup-success {
	margin: 20px 0 0;
	padding: 14px;
	background: $brand-blue;
	opacity: 1;
	max-width: 900px;
  -webkit-animation: fadein 500ms;
   -moz-animation: fadein 500ms;
    -ms-animation: fadein 500ms;
     -o-animation: fadein 500ms;
        animation: fadein 500ms;

	h4 {
		margin: 0 0 10px;
		color: #fff;
	}

	p {
		margin: 0;
		color: #fff;
	}
}

form.signup-form {
	overflow: auto;
	max-width: 900px;

	div.field {
		max-width: 400px;
	}

	.Actions {
		margin-top: 10px;
	}

	@include respond(md) {
		max-width: 900px;
		padding-right: 120px;
		position: relative;

		fieldset {
    	max-width: 800px;
		}

		.Actions {
			width: 120px;
			position: absolute;
			top: 14px;
			right: 0;

			.action {
				width: 100%;
			}
		}

		div.field {
			margin-bottom: 0;
			float: left;
			width: 50%;
			padding-right: 20px;
		}
	}
}
