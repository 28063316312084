/*
#Ambassador

Markup:

<div>
	<div class="ambassador" data-slider>
		<div class="ambassador-item">
			<div class="ambassador-inner">
				<div class="ambassador-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>
				<div class="ambassador-content">
				<div class="wrapper">
					<h3 class="ambassador-title">
						<small class="ambassador-pre">Ignite sport<br />ambassador:</small>
						<span>SBW</span>
					</h3>
					<p class="ambassador-text">Vivamus suscipit tortor eget felis porttitor volutpat.
					Donec rutrum congue leo eget malesuada. Donec sollicitudin
					molestie malesuada. Vestibulum ac diam.</p>
				</div>
				</div>
			</div>
		</div>
		<div class="ambassador-item">
			<div class="ambassador-inner">
				<div class="ambassador-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>
				<div class="ambassador-content">
				<div class="wrapper">
					<h3 class="ambassador-title">
						<small class="ambassador-pre">Ignite sport<br />ambassador:</small>
						<span>Nick Willis</span>
					</h3>
					<p class="ambassador-text">Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
				</div>
				</div>
			</div>
		</div>
		<div class="ambassador-item">
			<div class="ambassador-inner">
				<div class="ambassador-background" style="background-image: url(/themes/default/images/sbw.jpeg);"></div>
				<div class="ambassador-content">
				<div class="wrapper">
					<h3 class="ambassador-title">
						<small class="ambassador-pre">Ignite sport<br />ambassador:</small>
						<span>Nick Willis</span>
					</h3>
					<p class="ambassador-text">Vivamus suscipit tortor eget felis porttitor volutpat.
					Donec rutrum congue leo eget malesuada. Donec sollicitudin
					molestie malesuada. Vestibulum ac diam.</p>
				</div>
				</div>
			</div>
		</div>
	</div>
</div>

Styleguide 6.7

*/

.ambassador {
	position: relative;
}

.ambassador-item {
	position: relative;
}

.ambassador-inner {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ambassador-content {
	position: relative;
	z-index: 4;
	width: 100%;
	padding: $component-padding 0;
}

.ambassador-title {
	margin: 0;
	color: #fff;
	text-transform: uppercase;
	font-family: $font-heading;

	small {
		display: block;
		font-size: 28px;
		line-height: 28px;
		letter-spacing: 1px;
		width: 80%;
		padding: 0 0 8px 0;
		border-bottom: $font-heading-border;
	}

	span {
		display: block;
		font-size: 32px;
		line-height: 28px;
		letter-spacing: 0.75px;
		width: 80%;
		margin: 36px 0 16px;
	}

	@include respond(sm) {
		small, span {
			width: 66%;
		}
	}

	@include respond(md) {
		small, span {
			width: 50%;
		}
	}

}

.ambassador-text {
	color: #fff;
	margin: 0;
	width: 100%;
	font-size: 14px;
	line-height: 22px;

	@include respond(sm) {
		width: 66%;
	}

	@include respond(md) {
		width: 50%;
	}

	p {
		color: #fff;
	}

	a,
	a:visited,
	a:focus {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}

.ambassador-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-size: cover;
	background-position: right top;
	background-repeat: no-repeat;
}
