/*
#Footer

Footer stub

Template: Footer

Styleguide 6.2

*/


.footer {
	padding: 30px 0;
	background: #F5F5F5;

	@include respond(sm) {
		padding: 60px 0;
	}

	.contact-address {
		margin-top: 25px;
	}
}

.footer .footer-title {
	padding: 12px 0 0;
	margin: 50px 0 0;
	border-top: $font-heading-border-dark;
	color: $font-heading-color;
	width: 80%;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 0.75px;

	@include respond(sm) {
		width: 75%;
		margin: 0 0 10px;
	}

	@include respond(md) {
		width: 66%;
	}

}

.footer p {
	font-size: 13px;
	line-height: 20px;
	margin: 0 0 10px;

	@include respond(sm) {
		padding-right: 20px;
	}

	&.scrolltop {
		margin: 0 0 30px 0;
	}


}


.footer p.footer-copy {
	margin: 14px 0;
}

.footer-social {
	margin: 0 0px 50px;
	min-height: 32px;
}
