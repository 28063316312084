/*
#HEader

Markup:

<div>
	<div class="header">
		<div class="wrapper">
			<a class="header-brand">
				<img src="themes/default/images/svg/logo-white.svg" alt="Ignite Sport" />
			</a>
			<button class="hamburger pure-button" data-toggle-self data-toggle="#main-menu" data-switchsearch data-switch-off="[data-switchmenu]">
				<span class="hamburger-box">
					<span class="hamburger-inner"></span>
				</span>
				<span class="hamburger-text">Menu</span>
			</button>
		</div>
	</div>
</div>

Styleguide 6.1

*/


.header {
	background: #000;
	position: relative;
	padding: 15px 0;
	z-index: 4;
	@include transition(0.2s);

	.HomePage & {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 5;
		background: transparent
	}

	&.header-stickable {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 20;
		background: rgba(#000, 0.9);
	}

	&.header--fadein {
		opacity: 1;
		-webkit-animation: fadein 500ms;
   -moz-animation: fadein 500ms;
    -ms-animation: fadein 500ms;
     -o-animation: fadein 500ms;
        animation: fadein 500ms;
	}

	@include respond(md) {
		padding: 20px 0;
	}
}

.header-brand {
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	padding: 0;
	@include transition(0.2s);

	a {
		border: none;
	}

	img {
		@include transition(0.2s);
		height: 40px;
	}

	@include respond(md) {
		img {
			height: 50px;
		}
	}

	@include respond(lg) {
		img {
			height: 65px;
		}
	}
}

.header-sticky:not(.response_base):not(.response_small) .header--stickable {
	padding: 10px 0;

	.header-brand {
		img {
			height: 40px;
		}
	}
}

