div#BetterNavigator {
	top: 50%;
	margin-top: -80px;

	&.open {
		margin: 0;
		top: 0;
	}
	@include respond(print) {
		display: none;
	}
}
