

.sociallink {
	float: left;
	overflow: hidden;
	width: 32px;
	height: 0;
	padding-top: 32px;
	margin: 20px 16px 0 0;
	display: block;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include transition(0.2s);

	&--facebook {
		background-image: url('/themes/default/images/svg/facebook.svg');
	}

	&--twitter {
		background-image: url('/themes/default/images/svg/twitter.svg');
	}

	&--youtube {
		background-image: url('/themes/default/images/svg/youtube.svg');
		width: 81px;
	}

	&--givealittle {
		background-image: url('/themes/default/images/givealittle.png');
		width: 112px;
	}

	&:hover {
		transform: scale(1.05);
	}
}